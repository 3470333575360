
import { defineStore } from "pinia";
import { login,getInfo,logout } from "@/api/login";
import { setToken,getToken,removeToken,removeSysArr } from "@/utils/auth";

 const useUserStore:any=defineStore("user",{
    state:()=>{
        return {
            isLogin:false,
            token:getToken(),
            name:"",
            avatar:"",
            roles:[],
            permissions:[],
            subsysList:[],//子系统数组
            userInfoData:null//登录用户信息  

        }
    },
    getters:{},
    actions:{
        loginPinia(userInfo){
            const username=userInfo.username.trim()
            const password=userInfo.password
            const code=userInfo.code
            const uuid=userInfo.uuid
            return new Promise<void>((resolve,reject)=>{
                // login方法只是拿到token
                login({username,password,code,uuid}).then((res)=>{
                    console.log(res,"<<<=== res 在pinia请求login")
                    // 拿到login请求返回的token
                    console.log(res.data.access_token,"<<<=== res.data.access_token");
                    // 保存token到cookie容器中
                    setToken(res.data.access_token)
                    // 将拿到的token保存到仓库中
                    this.token=res.data.access_token
                    // 改变主应用的登录状态
                    this.isLogin=true
                    console.log(this.isLogin,"<<<=== this.isLogin usrStore仓库"); 
                    

                    // 发请求拿到用户信息
                    getInfo().then((res:any)=>{
                        console.log(res,"<<<=== getInfo拿到登录的详细信息res");
                        this.userInfoData=res
                        this.subsysList=res.subsysList
                        console.log(this.subsysList,"<<<=== this.subsysList");
                        console.log(this.userInfoData,"<<<=== this.userInfoData---仓库里用户详细信息");
                        // 将数据保存到本地
                        localStorage.setItem("userInfoData",JSON.stringify(res))
                        resolve(res)
                        
                    })
                    
                }).catch((error)=>{
                    console.log(error,"<<<=== error 仓库");
                    reject(error)
                })
            })
        },
        logOut() {
            return new Promise((resolve, reject) => { 
              logout(this.token).then((res) => {
                this.token = ''
                this.roles = []
                this.permissions = []
                removeToken()
                // removeSysArr()
                resolve(res)
              }).catch(error => {
                reject(error)
              })
            })
          }

    }
})


export default useUserStore