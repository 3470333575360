<template>
    <div class="login-page">

        <el-form class="login-form" :model="form" label-width="55px" size="large">
            <h1 class="title">普康养老云平台</h1>

            <el-form-item label="用户名">
                <el-input type="text" size="large" v-model.trim="form.username" placeholder="请输入用户名">
                    <template v-slot:prefix>
                        <el-icon>
                            <Avatar />
                        </el-icon>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item label="密码">
                <el-input show-password type="password" size="large" v-model.trim="form.password" placeholder="请输入密码">
                    <template v-slot:prefix>
                        <el-icon>
                            <Lock />
                        </el-icon>
                    </template>

                </el-input>
            </el-form-item>

            <el-form-item>
                <el-input placeholder="请输入验证码" style="width: 60%;" v-model.number="form.code">
                    <template v-slot:prefix>
                      <SvgIcon icon-class="valid"></SvgIcon>
                    </template>
                </el-input>
                <div class="captcha">
                    <img class="captcha-img" :src="codeUrl" @click="handleCode" alt="">
                </div>
            </el-form-item>

            <el-form-item>
                <el-button @click="handleLogin" style="width: 100%;" type="primary" size="large">登录</el-button>
            </el-form-item>
        </el-form>
        <div style="position: fixed;bottom: 30px"><a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案号：浙ICP备2022026243号-4</a>    <!--网站域名：<a style="color:#409eff;" href="https://beian.miit.gov.cn/#/Integrated/index">zjpucom.com</a>--></div>
    </div>
</template>

<script lang="ts" setup>
import { getCode } from "@/api/login.ts"
import { reactive, ref, onMounted, getCurrentInstance } from 'vue'
import useUserStore from "@/stores/user"
import { useRouter } from "vue-router"

const router = useRouter()
const userStore = useUserStore()

const codeUrl = ref()
const form = reactive({
    username: "",
    password: "",
    code: "",
    uuid: ""
})


const cxt = getCurrentInstance()//拿到当前组件实例对象
console.log(window.DUER_rtcsdk, "<<<=== cxt");

const eventBus = cxt.appContext.config.globalProperties.$eventBus
console.log(eventBus, "<<<==== eventBus---------------------------------");

onMounted(() => {
    console.log("挂载了");
    handleCode()
})


// 验证码
function handleCode() {
    getCode().then((res: any) => {
        codeUrl.value = "data:image/gif;base64," + res.img
        form.uuid = res.uuid

    })

}

// 登录按钮
function handleLogin() {
    console.log("点击了登录按钮");
    userStore.loginPinia(form).then((res: any) => {
        console.log(res, "<<<=== res 点击登录拿到用户信息");
        // 触发getSysArr事件，把参数传递过去
        eventBus.emit("getSysArr", res)

        // cxt.appContext.config.globalProperties.$actions.setGlobalState({
        //     name: "变了信息",
        //     subsysListGlobal: res.subsysList
        // })

        console.log(res.subsysList.length, "<<<==== res.subsysList.length=============长度");

        // 如果子系统数组有值
        if(res.subsysList && res.subsysList.length > 0) {
          localStorage.setItem("subsysid", res.subsysList[0].id)
            router.push({
                path: `${res.subsysList[0].subsysId}${res.subsysList[0].uri}`,
                query: { id: res.subsysList[0].id }
            })
        }else{
            router.push({
                path:"/homepage/index"
            })
        }



    })
}



</script>

<style lang="scss" scoped>
.captcha {
    width: 33%;
    height: 40px;

    .captcha-img {
        height: 40px;
        padding-left: 12px;
    }
}

.title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-weight: 600;
}

.login-page {
    box-sizing: border-box;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("@/assets/images/login.png");
    background-size: cover;
}

.login-form {
    border-radius: 10px;
    background-color: #ffffff;
    width: 400px;
    padding: 25px 30px 20px 25px;




}
</style>@/stores
