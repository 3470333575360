
import { createRouter,createWebHistory } from "vue-router";
import { RouteRecordRaw } from 'vue-router'

import Login from "@/views/Login.vue" 


// 公共路由
const constantRoutes:RouteRecordRaw[]=[
    {
        path:"/",
        redirect:"/login"
        // component:HomePage
    },
    {
        path:"/login",
        component:Login
    }



]

const router=createRouter({
    history:createWebHistory(),
    routes:constantRoutes
})

export default router