<template>
    <div class="pk-header">

        <div class="child-system" :style="{'margin-left' :marginLeft +'px'}">
            <el-form>
                <el-form-item>
                    <el-select v-model="sysDataId" value-key="id" placeholder="请选择子系统" size="large" @change="handleSelectChange" style="width: 250px">
                        <el-option v-for="item in sysArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>

      <div style="display: flex;justify-content: center;align-items: center">
        <div class="user-name">{{ userInfo }}</div>

        <div class="avatar-box">

          <el-dropdown max-height="60px" @command="handleCommand">
                <span class="el-dropdown-link">
                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                    <el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>


        <!-- 修改密码弹框 -->
        <el-dialog v-model="dialogVisible" title="修改密码" width="30%" :before-close="handleClose">
            <div>
                <el-form ref="formRef" label-width="80px" size="default" :model="passwordForm" :rules="rules">

                    <el-form-item label="原密码" prop="oldPassword">
                        <el-input show-password clearable v-model.trim="passwordForm.oldPassword"></el-input>
                    </el-form-item>

                    <el-form-item label="新密码" prop="password">
                        <el-input show-password clearable v-model.trim="passwordForm.password" @input="onInputChange"></el-input>
                    </el-form-item>

                    <el-form-item label="确认密码" prop="confirmPassword">
                        <el-input show-password clearable v-model.trim="passwordForm.confirmPassword"></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="default" @click="handleClose">取消</el-button>
                    <el-button size="default" type="primary" @click="onOkBtn">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>



    </div>
</template>

<script setup lang="ts">
import { updateUserPassword } from "@/api/login.ts"
import { ElMessageBox, ElMessage } from 'element-plus'
import useUserStore from "@/stores/user"
import { useRouter,useRoute } from 'vue-router';
import { reactive, onMounted, ref, getCurrentInstance,nextTick  } from 'vue';
import { setSysArr } from "@/utils/auth"

console.log("进入头部页面");

const rules = reactive({
    password: [
        { required: true, validator: validatePassword, trigger: 'blur' },
    ],
    oldPassword:[
        {required:true,validator:validateOldPassword,trigger:'blur'}
    ],
    confirmPassword:[
        {required:true,validator:validateConfirmPassword,trigger:'blur'}
    ]
})
const passwordForm = reactive({
    password: '',
    oldPassword:'',
    confirmPassword:''
})

const dialogVisible = ref(false)
const router = useRouter()
const route=useRoute()
const sysArr = ref([])
// 拿到用户信息
const userInfo = ref(null)
const sysDataId = ref()
const userId = ref()
// 有没有监听到事件总线的开关
const eventFlag = ref(false)
 const marginLeft=ref(210)
// 拿到当前组件实例对象
const cxt = getCurrentInstance()
// 拿到全局事件总线
const eventBus = cxt.appContext.config.globalProperties.$eventBus


onMounted(() => {
    // 监听getSysArr事件，拿到传过来的值
    eventBus.on("getSysArr", (res) => {
        console.log(res,"<<<===挂载时拿到的数据-res");

        eventFlag.value = true//监听到了，打开开关
        userId.value = res.user.userId
        userInfo.value = res.user.userName
        console.log(res, "<<<+++ 监听事件总线拿到的res");

        // 保存到将拿到的用户详细信息保存到cookie中
        // setSysArr(res)
        // 显示子系统下拉数组
        sysArr.value = res.subsysList
        console.log(sysArr.value, "<<<=== sysArr.value---------子系统下拉数组");
        sysDataId.value = res.subsysList[0].id
        console.log(sysDataId.value, "<<<=== sysDataId.value");
    })

    // 刷新页面没有触发getSysArr事件时从仓库拿数据
    if (!eventFlag.value) {
        // 下拉选择后页面数据会更新，当你跳转页面时，页面会刷新，下拉选中的数据会丢失，现在手动选中
        // 选择下拉列表中的子系统时将选中的子系统id保存到本地存储中
        const currentId=JSON.parse(localStorage.getItem("subsysid"))
        console.log(currentId,"currentId================");
        sysDataId.value=currentId


        console.log(useUserStore(), "<<<=== 头部挂载时，从仓库中拿数据useUserStore()");
        console.log(JSON.parse(localStorage.getItem("userInfoData")), "<<<=== 从本地拿到的用户详细信息--userInfoData");
        // 从本地拿数据
        sysArr.value = JSON.parse(localStorage.getItem("userInfoData"))?.subsysList
        console.log(sysArr.value, "<<<=== 从本地拿到的下拉数组--sysArr.value");
        // sysDataId.value = sysArr.value ? sysArr.value[0].id : ''
        userInfo.value = JSON.parse(localStorage.getItem("userInfoData")) ? JSON.parse(localStorage.getItem("userInfoData")).user.userName : ''



    }
})

/**自定义校验 */

/**校验原密码 */
function validateOldPassword(rule,val,callback){
    // 正则
    // 如果没有输入
    if(val=== ''){
        callback(new Error("原密码不能为空"))
    }else{
        callback()
    }

}

/**校验新密码 */
function validatePassword(rule, val, callback) {
    console.log("执行");
    console.log(val, "val===新密码");
    // 正则
    // 如果没有输入
    if (val === '') {
        callback(new Error("新密码不能为空"))
    } else {
        callback()
    }

}

/**校验确认密码 */
function validateConfirmPassword(rule,val,callback){
    // 正则
    // 如果没有输入
    console.log(val, "val===确认密码");
    if(val === ''){
        callback(new Error("确认密码不能为空"))
    }else if(passwordForm.password !== val){
        callback(new Error("两次输入的密码不一致"))
    }else{
        callback()
    }
}

/**输入框的值改变时触发 */
function onInputChange(e) {
    // console.log(e, "<<<=== 输入框的e");
    // console.log(passwordForm.password, "<<<=== passwordForm.password");
}

// 在主系统中监听来自子系统的事件
window.addEventListener('subAppEvent', event => {
  // 处理从子系统传递过来的参数
  const data = event.detail;
  if(data.message==true){
    marginLeft.value=210
  }else {
    marginLeft.value=70
  }

});
/**修改密码点确定 */
function onOkBtn() {
    console.log("点击确定按钮");
    console.log(userId.value, "<<<===userId.value");

    const formRef = cxt.proxy.$refs.formRef as any
    console.log(formRef,"formRef++++++++++++++=================================--------------------------------=========");

    formRef.validate((valid)=>{
        console.log(valid,"valid");
        if(!valid){
            console.log("整个表单校验失败");
            return
        }
        console.log("整个表单校验成功");
        updateUserPassword(passwordForm.oldPassword,passwordForm.password).then((res)=>{
            console.log(res,"<<<==== res 修改密码");
            handleClose()
            ElMessage.success({
                message:"密码修改成功，请退出重新登录",
                type:"success"
            })
        })
    })
}

/**关闭修改密码弹框 */
function handleClose() {
    const formRef = cxt.proxy.$refs.formRef as any
    dialogVisible.value = false
    console.log("点击关闭修改密码弹框");
    // 重置所有的表单项，恢复初始值
    formRef.resetFields()
    // 清空校验样式
    formRef.clearValidate()


}

// 监听是退出登录和修改密码
const handleCommand = (command) => {

    console.log("选了");
    console.log(command, "<<<=== command");
    if (command === "logout") {
        logout()
        console.log("处理登出的逻辑");

    } else {
        dialogVisible.value = true
        console.log("处理修改密码的逻辑");
    }
}


/**下拉选择选中触发事件 */
const handleSelectChange = (val) => {
    console.log(val, "<<<=== 当前选择的值");
    console.log('22222', sysArr.value)
  let obj=sysArr.value.find(item=>item.id==val)
  console.log('22222222',obj)
    // 本地保存通过查询字符串传过来的子系统id
    localStorage.setItem("subsysid", JSON.stringify(val))
    // 激活子应用
    router.push({
            path: `/${obj.subsysId}${obj.uri}`,
            query: { id: val }
        })




    // 改变全局数据，将选中的子系统id赋值给sysChecked
    // cxt.appContext.config.globalProperties.$actions.setGlobalState({
    //     sysChecked: val
    // })

}


function logout() {
    ElMessageBox.confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        useUserStore().logOut().then((res) => {
            console.log(res, "<<<===-------------操作res-----------------");
            router.push("/login")
            ElMessage({
                message: "登出成功",
                type: 'success'
            })
            sysArr.value = []
            sysDataId.value = null
            // 清空本地存储
            localStorage.removeItem("userInfoData")


        })
    }).catch((error) => {
        console.log(error, "<<<=== error");

    });
}


</script>

<style scoped lang="scss">
@include b(header) {
    box-sizing: border-box;
    overflow: hidden;
    height: 60px;
    background-color:rgb(64, 158, 255);
    display: flex;
    flex-direction: row;
     align-items: center;
    justify-content: space-between;


}

.el-dropdown :hover {
    border: none;
    outline: none;
}



.child-system {
    width: 300px;
    height: 60px;
    margin-right: 20px;
    //margin-left: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-form-item--small {
        margin-bottom: 0px;
    }

}

.avatar-box {
    width: 100px;
    margin-right: 20px;
}

.el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

.user-name {
    margin-right: 20px;
   color: #FFFFFF;
}
</style>


