
import Cookies from "js-cookie"

const Token="token"
const SysArr="sysArr"

// 保存token
export function setToken(token){
    return Cookies.set(Token,token)

}

// 拿到token
export function getToken(){
    return Cookies.get(Token)
}

// 删除token
export function removeToken(){
    return Cookies.remove(Token)
}

//保存下拉系统数组
export function setSysArr(data){
    console.log(data,"cookie中保存data--------------------------------------------------------------------------------");
    
    // const list=JSON.stringify(data)
    return Cookies.set(SysArr,JSON.stringify(data))

}

// 拿到下拉系统数组

export function getSysArr(){
    return JSON.parse(Cookies.get(SysArr))
}

// 删除下拉系统数组
export function removeSysArr(){
    return Cookies.remove(SysArr)
}



