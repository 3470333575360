
import "@/css/reset.css"
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// @ts-ignore
import App from './App.vue'
import router from "./router/index"
import { registerMicroApps, start, initGlobalState } from 'qiankun'
import pinia from "@/stores"
import mitt from "mitt"
import useUserStore from "@/stores/user"
import SvgIcon from "@/components/SvgIcon/index.vue"
import 'virtual:svg-icons-register'
import './xiaodu.js'
// 设置全局变量
const state = {
  name: "哈哈",
  subsysListGlobal: [],
  sysChecked: ''
}

// 初始化全局变量
const actions = initGlobalState(state)
// console.log(actions, "<<<=== actions-------------------------------初始化全局变量");

// 监听全局变量的变化，改变时触发
actions.onGlobalStateChange((state, previous) => {
  // console.log("主应用触发---全局变量改变");
  // console.log(state, "<<<=== state");
  // console.log(previous, "<<<=== previous");
})


const eventBus = mitt()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.component("SvgIcon", SvgIcon)
const userStore = useUserStore()

// 全局注册图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, { size: "small" })
// 注册全局事件总线
app.config.globalProperties.$eventBus = eventBus
// 注册全局的qiankun状态管理actions
app.config.globalProperties.$actions = actions

// console.log(userStore.isLogin, "<<<=== userStore main.js中");
// console.log(userStore.subsysList, "<<<=== userStore.subsysList---- main.js中");

const currentEnv = import.meta.env

// console.log(currentEnv, "<<<=== currenEnv");

registerMicroApps([
  {
    name: "homepage",//子应用package的name字段
    //entry: "http://192.168.3.8:9999/",
    entry: currentEnv.PROD ? "/child/homepage/" : "http://192.168.3.2:9999/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/homepage", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "SystemScreen",//子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/SystemScreen/" : "http://192.168.3.2:8888/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/SystemScreen", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "intelligentService",//子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/intelligentService/" : "http://192.168.3.2:7777/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/intelligentService", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "iotintelligentservices",//子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/iotintelligentservices/" : "http://192.168.3.2:6061/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/iotintelligentservices", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "homeBed",//子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/homeBed/" : "http://192.168.3.2:5050/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/homeBed", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "pk-ltc-ins",// 子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/pk-ltc-ins/" : "http://192.168.3.2:28091/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/pk-ltc-ins", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  },
  {
    name: "pk-er-shop",// 子应用package的name字段
    // entry: "http://192.168.3.14:8888/", // 开发环境子应用在服务器的地址
    entry: currentEnv.PROD ? "/child/pk-er-shop/" : "http://192.168.3.2:7070/", // 生产环境子应用在服务器的地址
    // entry: "//192.168.3.4:80/child/homepage" 完整url路径
    container: "#container", // 展示的DOM容器 id
    activeRule: "/pk-er-shop", // 激活规则
    props: {
      state: state,
      subsysList: userStore.subsysList,
      isLogin: userStore.isLogin,
      router: router
    }
  }
], {
  beforeLoad(app: any): any {
    // console.log(app, "<<<=== beforeLoad 加载前 ");
  },
  beforeMount(app: any): any {
    // console.log(app, "<<<=== beforeMount 挂载前 ");
  },
  afterMount(app: any): any {
    // console.log(app, "<<<=== afterMount 挂载后 ");
  },
  beforeUnmount(app: any): any {
    // console.log(app, "<<<=== beforeUnmount 卸载前 ");
  },
  afterUnmount(app: any): any {
    // console.log(app, "<<<=== afterUnmount 卸载后 ");
    // 强制刷新，清空缓存
    window.location.reload()
  },

});

// @ts-ignore
// @ts-ignore
// @ts-ignore
start({
  prefetch: false,
  singular: true,
  // strictStyleIsolation: false,
  fetch: window.fetch,
  excludeAssetFilter: (assetUrl) => {
    const whiteList = ['webapi.amap.com']
    const whiteWords = ['webapi.amap.com']
    if (whiteList.includes(assetUrl)) {
      return true
    }
    return whiteWords.some((w) => {
      return assetUrl.includes(w)
    })
  }
})


app.mount("#app")

// console.log(app, 'app根实例对象')

interface LifeCycles<T extends object = {}> {
  beforeLoad?: (
    app: any,
  ) => Promise<void> | void;
  beforeMount?: (
    app: any,
  ) => Promise<void> | void;
  afterMount?: (
    app: any,
  ) => Promise<void> | void;
  beforeUnmount?: (
    app: any,
  ) => Promise<void> | void;
  afterUnmount?: (
    app: any,
  ) => Promise<void> | void;
  error?: (
    err: Error,
    app: any,
  ) => void;
}
