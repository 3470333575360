
import request from "@/utils/request.ts"

console.log(request, "<<<=== request实例对象");

// 拿到验证码
export function getCode() {
    return request({
        url: "/code",
        method: "get",
        timeout: 20000
    })
}

// 登录
export function login(params) {
    console.log(params, "<<<=== params login登录");
    return request({
        url: "auth/login",
        method: "post",
        data: params
    })
}

// 拿到用户的详细信息
export function getInfo() {
    return request({
        url: "/system/user/getInfo",
        method: "get"
    })
}

// 退出方法
export function logout(data?) {
    return request({
        url: '/auth/logout',
        method: 'delete'
    })
}


/**登录账号修改密码-新旧密码 */
export function updateUserPassword(oldPassword,newPassword){
    const data={
        oldPassword,
        newPassword
    }
    return request({
        url:"/system/user/profile/updatePwd",
        method:"put",
        params:data
    })

}