import axios from "axios";
import { getToken } from "./auth";
import { ElMessage } from "element-plus";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const instance=axios.create({
    // baseURL:"https://jsonplaceholder.typicode.com"
    baseURL:"/dev-api",
    timeout:5000
    
})




// 添加请求拦截器
instance.interceptors.request.use(
    // 发送请求前做什么
(config:any)=>{
    // 请求头中isToken的值是否为假，为假就是没设置，没设置就是真
    console.log(config.headers.isToken === false,"<<<=== config.headers.isToken");
    const isToken = (config.headers || {}).isToken === false;
    console.log(isToken,"<<<=== isToken");
    
    // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    if (getToken() && !isToken) {
        config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      }
    

    
    console.log(config,"<<<===request文件请求拦截器的config");
    return config
},
// 发送请求报错
(error)=>{
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(
    // 对响应数据做什么
    (res)=>{
        console.log(res,"<<<=== 响应拦截器-res");
        // 拿到状态码
        const code=res.data.code || 200
        console.log(code,"<<<=== code");
        
        // 拿到错误提示
        const msg=res.data.msg
        // 根据拿到的状态码进行判断
        if(code === 401){
            // 没有授权
            ElMessage({
                message:msg,
                type:"error"
            })
            return Promise.reject(new Error(msg))
        }else if(code === 500){
            // 服务器有问题
            ElMessage({
                message:msg,
                type:"error"
            })
            return Promise.reject("new Error(msg)")
        }else if(code !== 200){
            ElMessage({
                message:msg,
                type:"error"
            })
            return Promise.reject("error")
        }else{
            // 现在code是200
            console.log("<<<=== 响应拦截器的状态码是200");
            return Promise.resolve(res.data)

        }

        
        
    },
    // 响应报错做什么
    (error)=>{
        console.log(error,"<<<=== 响应拦截器 响应失败 -error");
        const {message} =error
        ElMessage({
            message:message,
            type:"error"
        })
        
        return Promise.reject(error)
    }
)


export default instance


// 封装axios的get请求
//  async function get(url){
//     console.log(url);
    
//     try{
//         const res=await instance.get(url)
//         console.log(res,"<<<===res get方法请求的结果");
//         return res
        
//     }catch(error){
//         console.log(error,"<<<===error get请求出错");
        
//     }

// }

// 封装axios的post请求

//  async function post(url,params){
//     console.log(url)
//     console.log(params,"params<<<===post请求");
    

//     try{
//         const res=await instance.post(url,params)
//         console.log(res,"<<<===res post方法请求的结果");
        
//     }catch(error){
//         console.log(error,"<<<===error post请求出错");
//     }
// }


// export default {
//     get,
//     post
// }
