
<template>
    <div class="app-page">
      <Header v-show="route.path !=='/login'"></Header>
      <!-- <Header></Header> -->
      <RouterView></RouterView>
      <div id="container"></div>
    </div>
  </template>

<script setup lang="ts">
import Header from "@/Layout/Header/index.vue"
import { watchEffect,onMounted,getCurrentInstance } from "vue"
import {useRoute,useRouter} from "vue-router"


const route=useRoute()
const router=useRouter()
const {proxy} =getCurrentInstance()

onMounted(()=>{
  console.log(proxy,"<<<=== proxy 挂载--------------------------");

})



// 监听url栏的地址变化
watchEffect(() => {
      console.log('URL栏的变化：', router.currentRoute.value.path);
    });







</script>

<style  lang="scss">
// App.vue中不加scoped，因为是全局生效的


.app-page{
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

}

#container{
  overflow-y: auto;
}


</style>

<style>


</style>
